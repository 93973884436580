<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              :to="{
                name: 'MainGroupbuyingsList',
              }"
              >總公司團購管理
            </b-breadcrumb-item>
            <b-breadcrumb-item active>
              {{ isEdit ? "編輯商品白名單" : "查看商品白名單" }}
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-card>
      <div class="row">
        <div class="col-12 col-xl-8">
          <h4 class="mb-2 font-weight-bold">
            {{ isEdit ? "編輯商品白名單" : "查看商品白名單" }}
          </h4>

          <section>
            <div class="col-12 col-xl-7 mb-4">
              <ProductViewer
                class="mb-4"
                :loading="isLoading"
                :product="product"
              />
            </div>

            <div v-if="isLoading">
              <b-skeleton width="85%" class="mb-1"></b-skeleton>
              <b-skeleton width="55%" class="mb-1"></b-skeleton>
              <b-skeleton width="70%" class="mb-1"></b-skeleton>
            </div>
            <div v-else>
              <b-form-group
                label-cols="6"
                label-cols-lg="2"
                label="商品編號"
                label-for="sale_page_code"
              >
                <div>
                  {{
                    eventProduct.product && eventProduct.product.sale_page_code
                  }}
                </div>
              </b-form-group>
              <b-form-group
                label-cols="6"
                label-cols-lg="2"
                label="商品名稱"
                label-for="title"
              >
                <span>{{
                  eventProduct.product && eventProduct.product.title
                }}</span>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="sm"
                label="下拉選單起始日"
                label-for="start_at"
              >
                <div v-if="isEdit" class="d-flex">
                  <div class="w-50">
                    <datepicker
                      placeholder="Select Date"
                      v-model="form.start_at.date"
                      bootstrap-styling
                      format="yyyy-MM-dd"
                      :language="zh"
                      :disabledDates="disabledDates"
                    ></datepicker>
                    <b-form-invalid-feedback
                      :state="!v$.form.start_at.date.required.$error"
                    >
                      此欄位為必填
                    </b-form-invalid-feedback>
                  </div>
                  <div class="ml-2 w-50">
                    <vue-timepicker
                      format="HH:mm"
                      v-model="form.start_at.time"
                      :input-class="['form-control']"
                    ></vue-timepicker>
                    <b-form-invalid-feedback
                      :state="
                        !v$.form.start_at.time.HH.$error ||
                        !v$.form.start_at.time.mm.$error
                      "
                    >
                      此欄位為必填
                    </b-form-invalid-feedback>
                  </div>
                </div>
                <div v-else>
                  {{
                    eventProduct.start_at &&
                    format(new Date(eventProduct.start_at), "yyyy-MM-dd HH:mm")
                  }}
                </div>
              </b-form-group>

              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="sm"
                label="下拉選單結束日"
                label-for="end_at"
              >
                <div v-if="isEdit" class="d-flex">
                  <div class="w-50">
                    <datepicker
                      placeholder="Select Date"
                      v-model="form.end_at.date"
                      bootstrap-styling
                      format="yyyy-MM-dd"
                      :language="zh"
                      :disabledDates="disabledDates"
                    ></datepicker>
                    <b-form-invalid-feedback
                      :state="!v$.form.end_at.date.required.$error"
                    >
                      此欄位為必填
                    </b-form-invalid-feedback>
                  </div>

                  <div class="ml-2 w-50">
                    <vue-timepicker
                      format="HH:mm"
                      v-model="form.end_at.time"
                      :input-class="['form-control']"
                    ></vue-timepicker>
                    <b-form-invalid-feedback
                      :state="
                        !v$.form.end_at.time.HH.$error ||
                        !v$.form.end_at.time.mm.$error
                      "
                    >
                      此欄位為必填
                    </b-form-invalid-feedback>
                  </div>
                </div>
                <div v-else>
                  {{
                    eventProduct.end_at &&
                    format(new Date(eventProduct.end_at), "yyyy-MM-dd HH:mm")
                  }}
                </div>
              </b-form-group>

              <b-form-group
                label-cols="6"
                label-cols-lg="2"
                label-size="sm"
                label="每人最高購買數"
                label-for="once_max_quantity"
              >
                <template v-if="isEdit">
                  <b-form-input
                    id="once_max_quantity"
                    class="mb-2 mr-sm-2 mb-sm-0"
                    style="width: 80px"
                    type="number"
                    v-model="form.once_max_quantity"
                    readonly
                  ></b-form-input>
                </template>
                <div v-else>
                  {{
                    eventProduct.product &&
                    eventProduct.product.skus[0] &&
                    eventProduct.product.skus[0].once_max_quantity
                  }}
                </div>
              </b-form-group>

              <b-form-group
                label-cols="6"
                label-cols-lg="2"
                label-size="sm"
                label="每人最低購買數"
                label-for="once_quantity"
              >
                <template v-if="isEdit">
                  <b-form-input
                    id="once_quantity"
                    class="mb-2 mr-sm-2 mb-sm-0"
                    style="width: 80px"
                    type="number"
                    v-model="form.once_quantity"
                    readonly
                  ></b-form-input>
                </template>
                <div v-else>
                  {{
                    eventProduct.product &&
                    eventProduct.product.skus[0] &&
                    eventProduct.product.skus[0].once_quantity
                  }}
                </div>
              </b-form-group>

              <b-form-group
                label-cols="6"
                label-cols-lg="2"
                label-size="sm"
                label="下單數量遞增數"
                label-for="once_increase_quantity"
              >
                <template v-if="isEdit">
                  <b-form-input
                    id="once_increase_quantity"
                    class="mb-2 mr-sm-2 mb-sm-0"
                    style="width: 80px"
                    type="number"
                    v-model="form.once_increase_quantity"
                    readonly
                  ></b-form-input>
                </template>
                <div v-else>
                  {{
                    eventProduct.product &&
                    eventProduct.product.skus[0] &&
                    eventProduct.product.skus[0].once_increase_quantity
                  }}
                </div>
              </b-form-group>

              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="sm"
                label="團購分店"
                label-for=""
              >
                <BranchesTable
                  v-if="eventProduct.target_branches"
                  :branches="eventProduct.target_branches"
                />
              </b-form-group>
            </div>
          </section>
        </div>
      </div>

      <div v-if="isEdit" class="text-center" style="margin-top: 80px">
        <div class="text-danger mb-2">
          白名單設定修改，不影響準備中/ 已開團商品
        </div>
        <b-button
          variant="success"
          @click="handleSubmit"
          :disabled="isSubmitting"
          :busy="isSubmitting"
        >
          <span
            v-if="isSubmitting"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <span v-if="!isSubmitting">確認修改</span>
        </b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import { format } from "date-fns";
import { mapState, mapGetters } from "vuex";
import eventProductApi from "@/apis/event-product";
import BranchesTable from "@/components/BranchesTable";
import ProductViewer from "@/components/Page/Dashboard/ProductViewer";
import { zh } from "vuejs-datepicker/dist/locale";
import subDays from "date-fns/subDays";
import set from "date-fns/set";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import isBefore from "date-fns/isBefore";
import Datepicker from "vuejs-datepicker";
import VueTimepicker from "vue2-timepicker";
import getHours from "date-fns/getHours";
import getMinutes from "date-fns/getMinutes";

export default {
  components: {
    Datepicker,
    VueTimepicker,
    BranchesTable,
    ProductViewer,
  },

  setup: () => ({ v$: useVuelidate({ $lazy: true }) }),

  data() {
    return {
      zh,
      isSubmitting: false,
      disabledDates: { to: subDays(new Date(), 1) },
      isLoading: false,
      eventProduct: {
        start_at: "",
        end_at: "",
        target_branches: [],
      },
      form: {
        branch_ids: [],
        products: [],
        start_at: {
          date: new Date(),
          time: {
            HH: "00",
            mm: "00",
          },
        },
        end_at: {
          date: new Date(),
          time: {
            HH: "23",
            mm: "59",
          },
        },
        once_quantity: null,
        once_max_quantity: null,
        once_increase_quantity: null,
      },
    };
  },

  validations() {
    return {
      form: {
        once_quantity: {
          required,
        },
        once_increase_quantity: { required },
        start_at: {
          date: {
            required,
            beforeEndAt: () => {
              const startDate = set(new Date(this.form.start_at.date), {
                hours: this.form.start_at.time.HH,
                minutes: this.form.start_at.time.mm,
              });

              const endDate = set(new Date(this.form.end_at.date), {
                hours: this.form.end_at.time.HH,
                minutes: this.form.end_at.time.mm,
              });

              return isBefore(startDate, endDate);
            },
          },
          time: {
            HH: { required },
            mm: { required },
          },
        },
        end_at: {
          date: { required },
          time: {
            HH: { required },
            mm: { required },
          },
        },
      },
    };
  },

  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
      myBranches: (state) => state.myBranches,
    }),
    ...mapGetters("general", ["branchOptions"]),

    isEdit() {
      return this.$route.name === "ProductWhiteListEdit";
    },
    product() {
      return this.eventProduct?.product;
    },
  },

  created() {
    this.fetchEventProduct();
  },

  methods: {
    format,
    async fetchEventProduct() {
      try {
        this.isLoading = true;
        const { data } = await eventProductApi.getEventProduct(
          this.$route.params.event_Product_id
        );
        this.eventProduct = data.data;
        const { start_at, end_at, product, target_branches } = data.data;
        const { once_quantity, once_increase_quantity, once_max_quantity } =
          product.skus?.[0];

        this.form = {
          ...this.form,
          branch_ids: target_branches.map(({ id }) => id),
          once_quantity,
          once_increase_quantity,
          once_max_quantity,
          start_at: {
            date: new Date(start_at),
            time: {
              HH: String(getHours(new Date(start_at))).padStart(2, "0"),
              mm: String(getMinutes(new Date(start_at))).padStart(2, "0"),
            },
          },
          end_at: {
            date: new Date(end_at),
            time: {
              HH: String(getHours(new Date(end_at))).padStart(2, "0"),
              mm: String(getMinutes(new Date(end_at))).padStart(2, "0"),
            },
          },
        };
      } catch (error) {
        console.log("error", error);
      }
      this.isLoading = false;
    },

    async handleSubmit() {
      const result = await this.v$.$validate();

      if (!result) return;

      this.isSubmitting = true;

      const form = {
        ...this.form,
        start_at: format(
          set(new Date(this.form.start_at.date), {
            hours: Number(this.form.start_at.time.HH),
            minutes: Number(this.form.start_at.time.mm),
            seconds: 0,
          }),
          "yyyy-MM-dd HH:mm:ss"
        ),
        end_at: format(
          set(new Date(this.form.end_at.date), {
            hours: this.form.end_at.time.HH,
            minutes: this.form.end_at.time.mm,
            seconds: 59,
          }),
          "yyyy-MM-dd HH:mm:ss"
        ),
      };

      try {
        var response = {};
        response = await eventProductApi.updateEventProduct(
          this.eventProduct.id,
          form
        );

        if (response.status === 200) {
          await this.$swal.fire({
            title: "成功",
            type: "success",
            text: "更新成功",
          });
        }
      } catch (error) {
        this.$swal("失敗", error.response.data.message, "error");
      }

      this.$router.push({ name: "ProductWhiteList" });
      this.isSubmitting = false;
    },
  },
};
</script>

<style>
.modal-backdrop {
  opacity: 0.5;
}
</style>
